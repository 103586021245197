import type { AxiosError } from 'axios'
import type { ErrorMap, TypeWarning } from './types'
import notification from './notification'

const map = new Map<string | number, ErrorMap>()

export default ({
  error,
  message,
  type = 'error',
}: {
  error: AxiosError
  message?: string | null
  type?: TypeWarning
}) => {
  const errorId = error.request?.responseURL

  let errorMessage = message

  if (!message) {
    errorMessage
      = ((error?.response?.data as any)?.message as string) ?? error?.message
  }

  if (!map.has(errorId)) {
    setTimeout(() => {
      const currentError = map.get(errorId)

      if (currentError) {
        notification[currentError.type]({
          message: currentError.message as string,
        })
        map.delete(errorId)
      }
    }, 200)
  }

  map.set(errorId, { type, message: errorMessage as string })
}
