import { BrowserTracing, Replay, init, vueRouterInstrumentation } from '@sentry/vue'
import type { App } from 'vue'
import type { Router } from 'vue-router'
import { fetchSystemVersion } from '@/modules/common/api'
import type { SentrySettings, SentrySettingsState } from '@/plugins/sentry/types'

const sentrySettings: SentrySettingsState = {
  environment: 'DEV',
  isEnabled: true,
  systemVersion: null,
}

export async function setSentry() {
  const sentrySettingsResponse = await fetch('/data/context/sentry')
  const sentrySettingsData = await sentrySettingsResponse.json()

  setSentrySettings(sentrySettingsData)
}

function setSentrySettings(settings: SentrySettings) {
  sentrySettings.environment = settings.environment
  sentrySettings.isEnabled = settings.isEnabled
}

export function getIsEnabledSentry() {
  return sentrySettings.isEnabled
}

export async function initSentry(app: App, router: Router) {
  if (!sentrySettings.isEnabled)
    return

  const isLocalhost = ['127.0.0.1', 'localhost'].includes(document.location.hostname)
  if (isLocalhost)
    return

  try {
    sentrySettings.systemVersion = await fetchSystemVersion()
  }
  catch (e: any) {
    console.error(e) // на logger заменять не нужно
  }
  finally {
    init({
      app,
      dsn: 'https://2c9f80271aaa469f969bd4789df82787@sentry.forpeople.dev/3',
      integrations: [
        new BrowserTracing({
          routingInstrumentation: vueRouterInstrumentation(router),
        }),
        new Replay(),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,

      release: sentrySettings.systemVersion?.build,
      environment: sentrySettings.environment,
      // debug: true, // use only for local development for more Sentry information if you need

      tunnel: '/sentry',

      tracingOptions: {
        trackComponents: true,
        timeout: 1000,
        hooks: ['mount', 'update', 'destroy'],
      },
    })
  }
}
