/**
 * Check value on null type.
 * @param v
 */
export const isNull = (v: any): boolean => v === null

/**
 * Check value on object type.
 * @param v
 */
export const isArray = (v: any): boolean => Array.isArray(v)

/**
 * @TODO: Remove lodash duplicated methods.
 * Check value on object type.
 * Keep in mind that null has type object
 * @param v
 */
export function isObject(v: any): boolean {
  return typeof v === 'object' && !isArray(v) && !isNull(v)
}

/**
 * Check value on number type.
 * @param v
 */
export const isNumber = (v: any): boolean => typeof v === 'number'

/**
 * Check value on string type.
 * @param v
 */
export const isString = (v: any): boolean => typeof v === 'string'

/**
 * Check value on boolean type.
 * @param v
 */
export const isBoolean = (v: any): boolean => typeof v === 'boolean'

export function hasOwnProperty<X extends object, Y extends PropertyKey>(
  obj: X,
  prop: Y,
): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop)
}

export function generateRandomUid() {
  return [Math.random(), Date.now()]
    .map(n => n.toString(36).substring(2, 10))
    .join('_')
}

export function addSearchParam(url: string, obj: { [key: string]: string | number }): string {
  const hasQuery = url.includes('?')
  const queryParams = Object.entries(obj).map(([key, value]) => `${key}=${value}`).join('&')
  const divider = hasQuery ? '&' : '?'
  return `${url}${divider}${queryParams}`
}
