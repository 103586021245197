import { computed, ref } from 'vue'
import type { Me, UserPermissions } from '@/modules/auth/types'
import { fetchMe } from '@/modules/auth/api'
import { DEFAULT_LOCALE } from '@/modules/i18n/data/constants'

const user = ref<Me>({
  actions: {} as Me['actions'],
  avatar: null,
  isImpersonating: false,
  isSuperAdmin: false,
  name: '',
  roles: [],
  userId: '',
  employees: [],
  language: DEFAULT_LOCALE,
})

const userId = computed(() => user.value.userId)
const employees = computed(() => user.value.employees)
const firstEmployee = computed(() => {
  const [first] = employees.value
  return first || undefined
})

export const locale = computed(() => user.value.language)
export function hasPermission(permission: UserPermissions): boolean {
  return Boolean(user.value.actions[permission])
}

const userLoaded = ref(false)

async function loadUser() {
  try {
    user.value = await fetchMe()

    userLoaded.value = true
  }
  catch (e: any) {
    // do not user logger here to avoid cycle dependency
    console.error(e)
  }
}

export function useUser() {
  return {
    user,
    userId,
    employees,
    firstEmployee,
    locale,
    userLoaded,
    loadUser,
    hasPermission,
  }
}
