import { lowerCase, upperFirst } from 'lodash'

/**
 * Get text for i18n state, when translate not found
 *
 * @example
 *  getNoTranslationText('schemas.theIndicatorIsSetForAllEmployees') -> 'The indicator is set for all employees'
 *  getNoTranslationText('baseServerError') -> 'Base server error'
 *  getNoTranslationText('cycle') -> 'Cycle'
 */
export function getNoTranslationText(key: string) {
  return upperFirst(lowerCase(key.split('.').at(-1)))
}
