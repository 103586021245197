import { setLocalization } from '@/localization'
import { setSentry } from '@/plugins/sentry'

async function main() {
  try {
    await setLocalization()
    await setSentry()
  }
  catch (e: any) {
    console.error(e) // на logger заменять не нужно
  }
  finally {
    import('./startApp')
  }
}

main()
