export async function parseServerResponseMessage(error: any): Promise<string> {
  const isResponseTypeBlob = error.response.request.responseType === 'blob'

  if (isResponseTypeBlob) {
    const rawResponseMessage = await error.response.data.text()
    const parsedErrorMessage = JSON.parse(rawResponseMessage)
    return parsedErrorMessage?.message
  }

  return error.response.data?.message
}
