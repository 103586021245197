import type { AxiosResponse } from 'axios'
import type {
  AuthOptions,
  LoginCredentials,
  LoginResponse,
  Me,
} from '../types'
import axiosInstance from '@/plugins/axios'

export function apiImpersonate(userId: string): Promise<void> {
  return axiosInstance.post(`data/admin/users/${userId}/impersonate`)
}

export function stopImpersonate(): Promise<void> {
  return axiosInstance.post('data/auth/stop-impersonate')
}

export async function fetchMe(): Promise<Me> {
  const response = await axiosInstance.get<Me>('data/auth/me')
  return response.data
}

export async function login(credentials: LoginCredentials): Promise<LoginResponse> {
  const response = await axiosInstance.post<
    LoginCredentials,
    AxiosResponse<LoginResponse>
  >('data/auth/login', credentials)
  return response.data
}

export function logout(): Promise<void> {
  return axiosInstance.post('data/auth/logout')
}

export async function fetchAuthOptions(): Promise<AuthOptions> {
  const response = await axiosInstance.get<AuthOptions>('data/auth/options')
  return response.data
}
