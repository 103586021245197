import { captureException, captureMessage } from '@sentry/vue'
import { createMessage } from './createMessage'
import { createContext } from './createContext'
import type { LoggerContext } from '@/modules/logger/types'
import { getIsEnabledSentry } from '@/plugins/sentry'

function fatal(message: string) {
  throw new Error(message)
}

function error(message: string | Error, context?: LoggerContext) {
  const errorMessage = createMessage(message, context?.method)
  const fetchError: Error = new Error(errorMessage)

  if (getIsEnabledSentry())
    captureException(fetchError, createContext(context))

  console.error(fetchError)
}

function warn(message: string, context?: LoggerContext) {
  const warnMessage = createMessage(message, context?.method)

  if (getIsEnabledSentry()) {
    captureMessage(warnMessage, {
      ...createContext(context),
      level: 'warning',
    })
  }

  console.warn(warnMessage)
}

function info(message: string, context?: LoggerContext) {
  const infoMessage = createMessage(message, context?.method)

  if (getIsEnabledSentry()) {
    captureMessage(infoMessage, {
      ...createContext(context),
      level: 'info',
    })
  }

  // eslint-disable-next-line no-console
  console.info(infoMessage)
}

export const logger = {
  fatal,
  error,
  warn,
  info,
}
