import type { LoggerContext } from '@/modules/logger/types'
import { useUser } from '@/modules/common/composables'

const { user } = useUser()

export function createContext(context?: LoggerContext): LoggerContext {
  if (!context)
    return {}

  const loggerContext: LoggerContext = {
    ...context,
    tags: {
      ...context?.tags,
      method: context?.method,
    },
  }

  if (user.value.userId) {
    loggerContext.user = {
      id: user.value.userId,
      username: user.value.name,
      language: user.value.language,
    }
  }

  return loggerContext
}
