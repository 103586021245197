import { getAppLocale, setLocalizationData } from '@/modules/i18n'
import type { AvailableLocale } from '@/modules/i18n/types'

async function getProdLocalizationData(locale: AvailableLocale) {
  const [localizationResponse] = await Promise.all([
    fetch('/data/localization/current', {
      headers: {
        'Accept-Language': locale,
      },
    }),
  ])
  const localizationData = await localizationResponse.json()

  return localizationData
}

async function importRu() {
  return await import('../../src/ForPeople.WebApi/FrontLocalizationData/ru.json')
    .then(module => module.default,
    )
}

const importEn = async () => await import('../../src/ForPeople.WebApi/FrontLocalizationData/en.json').then(module => module.default)

async function getDevLocalizationData(locale: AvailableLocale) {
  const devLocales = {
    ru: importRu(),
    en: importEn(),
  }

  return await devLocales[locale]
}

export function getEnvironment(): 'development' | 'production' {
  return (import.meta.env as any).MODE || 'development'
}

export async function setLocalization() {
  const locale = getAppLocale()
  let localizationData

  if (getEnvironment() === 'production')
    localizationData = await getProdLocalizationData(locale)
  else
    localizationData = await getDevLocalizationData(locale)

  setLocalizationData(localizationData)
}
